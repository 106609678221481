import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiResponseUserPreference, RequestHandlerOptions, UserPreference } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserPreferenceDataService extends BaseRequestControllerService<UserPreference> {
  private myPreferencesApi = '/my-preferences';
  private updatePreferencesApi = '/update-preferences';

  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.userOperations, '/user-preferences', ['id', 'code', 'sendEmails']);
  }

  myPreferences(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<ApiResponseUserPreference>(
      `${this.url}${this.myPreferencesApi}`,
      options
    ) as Observable<ApiResponseUserPreference>;
  }

  updatePreferences(body: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<any>(`${this.url}${this.updatePreferencesApi}`, body, options) as Observable<any>;
  }
}
